import './Results.css';
import React, { useState } from 'react';
import { ResultsModel } from './models/InputModel';
import { MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { getResultTable, getResultTotals } from './data/FormatResults';
import MyButton from './components/MyButton';
import { downloadCSV, downloadXLSX } from './data/Export';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import MySelect from './components/MySelect';

interface ResultsProps {
  results: ResultsModel | null;
}

function Results({ results }: ResultsProps) {

  const [tableDisplay, setTableDisplay] = useState<"Flat" | "Grid">("Flat");

  if (results == null) {
    return null;
  }

  const totals = getResultTotals(results);
  const [tableHeader, tableBody] = getResultTable(results);

  function flatTable() {
    const columnAlignment = tableHeader.map(c => c.label.includes("Date") ? 'left' : 'right');
    const columnClasses = tableHeader.map(c => "cell_" + c.key);

    return (
      <Table size="small" key="flatTable">
        <TableHead>
          <TableRow>
            {tableHeader.map((c, j) => {
              const cell = <TableCell className="tableHeader" align={columnAlignment[j]} key={c.key}>{c.label}</TableCell>;
              if (c.description) {
                return <Tooltip placement="top" title={c.description} key={c.key + "-tooltip"}>{cell}</Tooltip>
              }
              return cell;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableBody.map((r, i) =>
            <TableRow key={"results-row-" + i}>
              {r.map((c, j) => {
                return <TableCell className={columnClasses[j]} align={columnAlignment[j]} key={"results-cell-" + i + "-" + j}>{c}</TableCell>;
              })}
            </TableRow>
          )}
        </TableBody>
      </Table>
    );
  }

  function dataGrid() {
    const columns: GridColDef[] =
      tableHeader.map(c => {
        const a = c.label.includes("Date") ? 'left' : 'right';
        let w = 100;
        switch (c.key) {
          case "notional_amount":
            w = 150;
            break;
          case "notional_amount_compounded":
            w = 190;
            break;
          //case "effective_rfr":
          //case "compounding_factor":
          //case "annualized_ccr":
          //case "unannualized_ccr":
          //case "non_ccr":
          case "earned_interest_from_base":
          case "earned_interest_from_spread":
          case "earned_interest_from_adjustment":
          case "earned_interest_total":
          case "earned_interest_balance":
          case "accrued_interest_balance":
            w = 190;
            break;
        }
        return {
          field: c.key,
          headerName: c.label,
          description: c.description ? c.label + ": " + c.description : undefined,
          headerClassName: "tableHeader",
          headerAlign: a,
          align: a,
          width: w,
          cellClassName: "cell_" + c.key
        };
      });

    const rows =
      tableBody.map((r, i) => {
        const row: any = { id: i };
        for (let j = 0; j < r.length; j++) {
          row[columns[j].field] = r[j];
        }
        return row;
      });

    return (
      <div>
        <DataGrid
          key="dataGrid"
          columns={columns}
          rows={rows}
          sx={{width: "100%"}}
          autoHeight={true}
          rowHeight={38}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 25, 50]}
        />
      </div>
    );
  }

  return (
    <>
      {totals.map(t =>
        <div className="resultContainer" key={t.key}>
          <span className="resultLabel">{t.label}</span>
          <span className="resultValue" id={t.key}>{t.value}</span>
        </div>
      )}

      <TableContainer component={Paper} className="tableContainer">
        <h3 id="summaryOfRatesHeader" className="summaryOfRates">Summary of Rates</h3>

        <div className="tableButtons">
          <MySelect label="Table display" value={tableDisplay} onChange={setTableDisplay}>
            <MenuItem value="Flat">Flat table</MenuItem>
            <MenuItem value="Grid">Data grid</MenuItem>
          </MySelect>
          <MyButton onClick={() => downloadCSV(results)}>Download CSV</MyButton>
          <MyButton onClick={() => downloadXLSX(results)}>Download XLSX</MyButton>
        </div>

        {tableDisplay == "Flat" ? flatTable() : dataGrid()}

      </TableContainer>
    </>
  );
}

export default Results;
