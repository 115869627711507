import { utc } from "moment";
import { InputModel, ValidationInputs, ValidationResults } from "../models/InputModel";
import { isMocked } from "../services/CalcService";
import { lookupRFRvalue } from "./AllInputOptions";
import { dataDateFormat, isBusinessDay, isValidDate, shiftDate } from "./DateUtils";
//import { isTenorEnabled } from "./InputAvailability";

export function checkInvalidInput(inputs: InputModel, validationInputs: ValidationInputs): ValidationResults {
  const res: ValidationResults =
  {
    businessDayCount: 0,
    invalidInput: false
  };

  if (inputs.interestMethod === "Term") {
    res.maxEndDate = utc({ year: 2099, month: 11, date: 31 }); // "12/31/2099"
  } else if (inputs.interestMethod === "TermCME") {
    res.minStartDate = validationInputs.minStartDateWithRateData;
    res.maxEndDate = utc({ year: 2099, month: 11, date: 31 }); // "12/31/2099"
  } else {
    res.minStartDate = validationInputs.minStartDateWithRateData;
    res.maxEndDate = validationInputs.maxEndDateWithRateData;
    if (inputs.inclusiveInterest && res.maxEndDate) {
      res.maxEndDate = res.maxEndDate.clone(); 
      shiftDate(res.maxEndDate, -1, inputs, validationInputs);
    } 
  }

  // effective dates must fall betseen start and end dates
  res.minEffectiveDate = utc(inputs.commitment[0].effectiveDate, dataDateFormat);
  res.minEffectiveDate.add(1, 'd');
  res.maxEffectiveDate = utc(inputs.endDate, dataDateFormat);
  if (!inputs.inclusiveInterest) {
    res.maxEffectiveDate.subtract(1, 'd');
  }

  if (!isMocked) {
    if (!res.minStartDate || !validationInputs.maxEndDateWithRateData || !res.maxEndDate) {
      return res; // do not fail if initial range request did not returned yet
    }

    // set maxStartDate to one day before maxEndDate
    if (inputs.interestMethod === "TermCME") {
      res.maxStartDate = validationInputs.maxEndDateWithRateData.clone();
    } else {
      res.maxStartDate = res.maxEndDate.clone();
    }
    res.maxStartDate.subtract(1, 'd');
    // set minEndDate to one day after minStartDate
    res.minEndDate = res.minStartDate.clone();
    res.minEndDate.add(1, 'd');
  }

  const rfr = lookupRFRvalue(inputs.RFR);
  const isTerm = inputs.interestMethod === "Term" || inputs.interestMethod === "TermCME";

  function error(message?: string): ValidationResults {
    res.invalidInput = true;
    res.inputErrorMessage = message;
    return res;
  }

  const commitmentDates = inputs.commitment.map(d => utc(d.effectiveDate, dataDateFormat));
  const startDate = commitmentDates[0];
  const endDate = utc(inputs.endDate, dataDateFormat);

  if (inputs.interestMethod === "Term" || inputs.interestMethod === "TermCME") {
    res.businessDayCount = 0;
  } else {
    let date = startDate.clone();
    res.businessDayCount = 0;

    if (inputs.inclusiveInterest) {
      while (date.isSameOrBefore(endDate)) {
        shiftDate(date, 1, inputs, validationInputs);
        res.businessDayCount++;
      }
    } else {
      while (date.isBefore(endDate)) {
        shiftDate(date, 1, inputs, validationInputs);
        res.businessDayCount++;
      }
    }
  }

  if (!startDate || !startDate.isValid()) {
    return error("Start Date is missing or invalid.");
  }
  if (!inputs.endDate || !endDate.isValid()) {
    return error("End Date is missing or invalid.");
  }
  if (inputs.inclusiveInterest) {
    if (startDate.isAfter(endDate)) {
      console.log("validation", startDate, endDate)
      return error("Start Date cannot be later than End Date.");
    }
  } else {
    if (startDate.isSameOrAfter(endDate)) {
      return error("Start Date must be earlier than End Date if Inclusive Interest is off.");
    }
  }
  if (!isValidDate(inputs, validationInputs, startDate)) {
    return error("Start Date must be a business day for compounding calculations.");
  }
  if (!isValidDate(inputs, validationInputs, endDate)) {
    return error("End Date must be a business day for compounding calculations.");
  }
  for (var i = 1; i < inputs.commitment.length; i++) {
    const effectiveDate = commitmentDates[i];
    if (!effectiveDate || !effectiveDate.isValid()) {
      return error("An Effective Date is missing or invalid.");
    }
    if (effectiveDate.isSameOrBefore(startDate)) {
      return error(`Effective Date ${effectiveDate.format(rfr.dateFormat)} must be later than Start Date.`);
    }
    const prevEffectiveDate = inputs.commitment[i - 1].effectiveDate;
    if (effectiveDate.isSameOrBefore(prevEffectiveDate)) {
      return error(`Effective Date ${effectiveDate.format(rfr.dateFormat)} must be later than previous Effective Date.`);
    }
    if (inputs.inclusiveInterest) {
      if (effectiveDate.isAfter(endDate)) {
        return error(`Effective Date ${effectiveDate.format(rfr.dateFormat)} cannot be later than End Date.`);
      }
    } else {
      if (effectiveDate.isSameOrAfter(endDate)) {
        return error(`Effective Date ${effectiveDate.format(rfr.dateFormat)} must be earlier than End Date if Inclusive Interest is off.`);
      }
    }
    if (!isValidDate(inputs, validationInputs, effectiveDate)) {
      return error(`Effective Date ${effectiveDate.format(rfr.dateFormat)} must be a business day for compounding calculations.`);
    }
  }
  if (!isMocked && !isTerm && inputs.lockout >= res.businessDayCount) {
    return error(`Lockout value must be lower than the number of business days in date range. Set Lockout to be at most ${res.businessDayCount - 1}, or adjust Start or End Date.`);
  }
  let invalidInput =
    commitmentDates.some(r => !r.isValid())
    || !endDate.isValid()
    || inputs.selectedCountryForHolidays.length == 0;
  let inputErrorMessage;
  if (res.minStartDate && res.maxEndDate) { // do not check if initial range request did not returned yet
    const tooEarlyStartDate = startDate.isBefore(res.minStartDate);
    const tooLateEndDate = endDate.isAfter(res.maxEndDate);
    if (tooEarlyStartDate || tooLateEndDate) {
      invalidInput = true;
      const rfrName = rfr.name;
      //if (isTenorEnabled(inputs)) {
      //  rfrName += "-" + inputs.tenor.shortName;
      //}
      inputErrorMessage = `For ${rfrName} with Look Back ${inputs.lookBack} Lockout ${inputs.lockout}`;
      if (tooEarlyStartDate) {
        inputErrorMessage += `, minimum Start Date is ${res.minStartDate.format(rfr.dateFormat)}`;
        if (tooLateEndDate) {
          inputErrorMessage += " and";
        }
      }
      if (tooLateEndDate) {
        if (inputs.inclusiveInterest) {
          inputErrorMessage += " and Inclusive Interest"
        }
        inputErrorMessage += `, maximum End Date is ${res.maxEndDate.format(rfr.dateFormat)}`;
      }
      inputErrorMessage += ".";
    }
    //else if (inputs.interestMethod === "TermCME" && startDate.isAfter(validationInputs.maxStartDate)) {
    //  const rfrName = rfr.name + "-" + inputs.tenor.shortName;
    //  inputErrorMessage = `For ${rfrName} with Look Back ${inputs.lookBack} maximum Start Date is ${validationInputs.maxStartDate.format(rfr.dateFormat)}.`
    //}
  }
  function warning(message: string): ValidationResults {
    res.invalidInput = false;
    res.inputWarningMessage = message;
    return res;
  }
  if (invalidInput) {
    return error(inputErrorMessage);
  } else {
    if (inputs.interestMethod === "SimpleInArrears" || isTerm) {
      if (!isBusinessDay(validationInputs, startDate)) {
        return warning("Start Date is considered a non-business day as it does not have a published rate.");
      }
      if (!isBusinessDay(validationInputs, endDate)) {
        return warning("End Date is considered a non-business day as it does not have a published rate.");
      }
      for (var i = 1; i < inputs.commitment.length; i++) {
        const effectiveDate = commitmentDates[i];
        if (!isBusinessDay(validationInputs, effectiveDate)) {
          return warning(`Effective Date ${effectiveDate.format(rfr.dateFormat)} is considered a non-business day as it does not have a published rate.`);
        }
      }
    }
  }

  return res;
}
