import './Inputs.css';
import { Moment, utc } from 'moment';
import React, { useMemo } from 'react';
import { FloorTypeName, InputModel, ResultsModel, ValidationInputs, ValidationResults } from './models/InputModel';
import { dataDateFormat, isValidDate } from './data/DateUtils';
import { Divider, MenuItem } from '@mui/material';
import { RFRvalues, calendars, dayCountValues, lookBacks, lookupRFRvalue, lockouts, roundingValues, allInterestMethodValues } from './data/AllInputOptions';
import NumberInput from './components/NumberInput';
import DateInput from './components/DateInput';
import MyButton from './components/MyButton';
import MySelect from './components/MySelect';
import { getFloorTypeOptions, isBalanceTransferHidden, isFloorTypeDisabled, isInterestReceivedHidden, isLockoutDisabled, isLookbackDisabled, isRoundingEnabled, isTermRateEnabled } from './data/InputAvailability';
import { CommitmentChangeAction, InputAction } from './models/InputAction';
import FormattedNumberInput from './components/FormattedNumberInput';
import MyCheckbox from './components/MyCheckbox';

interface InputsProps {
  inputs: InputModel;
  dispatchInputs: React.Dispatch<InputAction>;
  validationInputs: ValidationInputs;
  validationResults: ValidationResults,
  results: ResultsModel | null;
  onCalculate: () => void;
  onReset: () => void;
}

function Inputs({ inputs, dispatchInputs, validationInputs, validationResults, results, onCalculate, onReset }: InputsProps) {

  function isNotValidDate(d: Moment): boolean {
    return !isValidDate(inputs, validationInputs, d);
  }

  const rfr = lookupRFRvalue(inputs.RFR);
  const locale = rfr.locale;
  const currency = rfr.cur;

  const endDateMoment =
    useMemo(() => utc(inputs.endDate, dataDateFormat), [inputs.endDate])

  const effectiveDateMoments =
    useMemo(() => inputs.commitment.map(c => utc(c.effectiveDate, dataDateFormat)), [inputs.commitment])

  function numberToString(n: number, digits: number = 2) {
    if (n == null) {
      return "";
    }
    return n.toLocaleString(locale, { minimumFractionDigits: digits, maximumFractionDigits: digits })
  }

  return (
    <div className="calculatorInputs">

      <Divider />

      <div>
        <FormattedNumberInput
          label="Initial Commitment"
          className="largeInput"
          id="initialCommitmentInput"
          step={1000000}
          value={inputs.commitment[0].notional}
          locale={locale}
          currency={currency}
          onInput={(v) => dispatchInputs({ type: "commitment", index: 0, value: { type: "notional", value: v ?? 0 } })}
        />

        <NumberInput
          label="Initial Spread %"
          className="mediumInput"
          id="spreadInput"
          step={0.1}
          value={inputs.commitment[0].spread}
          onInput={(v) => dispatchInputs({ type: "commitment", index: 0, value: { type: "spread", value: v ?? 0 } })}
        />

        <DateInput
          label="Start Date"
          className="largeInput"
          id="startDate"
          value={effectiveDateMoments[0]}
          dateFormat={rfr.dateFormat}
          minDate={validationResults.minStartDate}
          maxDate={validationResults.maxStartDate}
          shouldDisableDate={isNotValidDate}
          onChange={(d) => { if (d != null) dispatchInputs({ type: "commitment", index: 0, value: { type: "effectiveDate", value: d.format(dataDateFormat) } }) }}
        />

        <DateInput
          label="End Date"
          className="largeInput"
          id="endDate"
          value={endDateMoment}
          dateFormat={rfr.dateFormat}
          minDate={validationResults.minEndDate}
          maxDate={validationResults.maxEndDate}
          shouldDisableDate={isNotValidDate}
          onChange={(d) => { if (d != null) dispatchInputs({ type: "endDate", value: d.format(dataDateFormat) }) }}
        />

        <span className="smallSpace"></span>

        <MyCheckbox
          label="Inclusive Interest"
          id="inclusiveInterest"
          checked={inputs.inclusiveInterest}
          onChange={(v) => dispatchInputs({ type: "inclusiveInterest", value: v })}
        />

        <MyButton
          id="addCommitmentChange"
          onClick={() => dispatchInputs({ type: "addCommitment", validationInputs, validationResults })}
        >Add Commitment/Spread Change</MyButton>

        <MyButton
          id="reset"
          isSecondary={true}
          onClick={onReset}
        >Reset To Defaults</MyButton>

        <div id="inputError" className="inputErrorMessage">{validationResults.inputErrorMessage}</div>
        <div id="inputWarning" className="inputWarningMessage">{validationResults.inputWarningMessage}</div>

      </div>

      <Divider />

      {inputs.commitment.map((c, idx) => {
        if (idx == 0) {
          return null;
        }
        function dispatchCommitmentInput(action: CommitmentChangeAction) {
          dispatchInputs({ type: "commitment", index: idx, value: action });
        }
        const noBT = isBalanceTransferHidden(inputs.commitment[idx], inputs);
        const noIR = isInterestReceivedHidden(inputs.commitment[idx]);
        let interestAmount = results == null ? null : results.response.InterestReceived[idx];
        return (
          <div key={idx}>
            <div>
              <DateInput
                label="Effective Date"
                className="largeInput"
                id={"effectiveDate-" + idx}
                value={effectiveDateMoments[idx]}
                dateFormat={rfr.dateFormat}
                minDate={validationResults.minStartDate}
                maxDate={validationResults.maxEndDate}
                shouldDisableDate={isNotValidDate}
                onChange={(d) => { if (d != null) dispatchCommitmentInput({ type: "effectiveDate", value: d.format(dataDateFormat) }) }}
              />

              <NumberInput
                label="Spread % Change"
                className="mediumInput"
                id={"spreadChange-" + idx}
                step={0.1}
                value={inputs.commitment[idx].spreadChange}
                onInput={(v) => dispatchCommitmentInput({ type: "spreadChange", value: v ?? 0 })}
              />

              <NumberInput
                label="Current Spread %"
                className="mediumInput"
                id={"spreadCurrent-" + idx}
                step={0.1}
                value={inputs.commitment[idx].spread}
                onInput={(v) => dispatchCommitmentInput({ type: "spread", value: v ?? 0 })}
              />

              <FormattedNumberInput
                label="Commitment Change"
                className="largeInput"
                id={"commitmentChange-" + idx}
                step={1000000}
                value={inputs.commitment[idx].change}
                locale={locale}
                currency={currency}
                onInput={(v) => dispatchCommitmentInput({ type: "change", value: v ?? 0 })}
              />

              <FormattedNumberInput
                label="Current Commitment"
                className="largeInput"
                id={"commitmentCurrent-" + idx}
                step={1000000}
                value={inputs.commitment[idx].notional}
                locale={locale}
                currency={currency}
                onInput={(v) => dispatchCommitmentInput({type: "notional", value: v ?? 0 })}
              />

              {noBT && noIR ? null : <span className="smallSpace"></span> }
              
              {noBT ? null :
                <MyCheckbox
                  label="Balance Transfer"
                  id={"balanceTransfer-" + idx}
                  checked={inputs.commitment[idx].balanceTransfer}
                  onChange={(v) => dispatchCommitmentInput({ type: "balanceTransfer", value: v })}
                />
              }

              {noIR ? null :
                <MyCheckbox
                  label="Interest Received"
                  id={"interestReceived-" + idx}
                  checked={inputs.commitment[idx].interestReceived}
                  onChange={(v) => dispatchCommitmentInput({ type: "interestReceived", value: v })}
                />
              }

              <MyButton
                id={"removeCommitment-" + idx}
                onClick={() => dispatchCommitmentInput({ type: "removeCommitment" })}
              > - </MyButton>

              <MyButton
                id={"addCommitmentAfter-" + idx}
                onClick={() => dispatchCommitmentInput({ type: "addCommitment", validationInputs, validationResults })}
              > + </MyButton>

              {interestAmount ?
                <span className="resultContainer">
                  <span className="resultLabel">Interest Amount </span>
                  <span className="resultValue">{numberToString(interestAmount)}</span>
                </span>
                : null}

            </div>

            <Divider />
          </div>
        );
      })}

      <div>
        <MySelect
          label="Look Back"
          className="largeInput"
          id="lookbackSelect"
          menuClassName="numberSelect"
          disabled={isLookbackDisabled(inputs)}
          value={inputs.lookBack}
          renderValue={(i) => inputs.observationShift ? <>{i}<span> (Obs. Shift)</span></> : i}
          onChange={(lb) => dispatchInputs({ type: "lookBack", value: lb })}
          
        >
          {lookBacks.map(lb =>
            <MenuItem
              className="numberItem"
              id={"option-lookback-" + lb}
              value={lb}
              key={lb}
            >{lb}</MenuItem>
          )}
          <MyCheckbox
            label="Observation Shift"
            className="obsShift"
            id="observationShiftCheckbox"
            checked={inputs.observationShift}
            onChange={(v) => dispatchInputs({ type: "observationShift", value: v })}
          />
        </MySelect>

        <MySelect
          label="Lockout"
          className="smallInput"
          id="lockoutSelect"
          menuClassName="numberSelect"
          disabled={isLockoutDisabled(inputs)}
          value={inputs.lockout}
          onChange={(lo) => dispatchInputs({ type: "lockout", value: lo })}
        >
          {lockouts.map(r =>
            <MenuItem
              className="numberItem"
              id={"option-lockout-" + r}
              value={r}
              key={r}
            >{r}</MenuItem>
          )}
        </MySelect>

        <NumberInput
          label="Adjustment %"
          className="smallInput"
          id="adjustmentInput"
          step={0.1}
          value={inputs.adjustment}
          onInput={(v) => dispatchInputs(({ type: "adjustment", value: v ?? 0 }))}
        />

        <NumberInput
          label="Floor %"
          className="smallInput"
          id="floorInput"
          placeholder="No Floor"
          step={0.1}
          value={inputs.floor}
          onInput={(v) => dispatchInputs(({ type: "floor", value: v }))}
        />

        <MySelect
          label="Floor Type"
          className="largerInput"
          id="floorTypeSelect"
          disabled={isFloorTypeDisabled(inputs)}
          value={inputs.floorType ?? ""}
          onChange={(ft) => dispatchInputs({ type: "floorType", value: ft as FloorTypeName })}
        >
          {getFloorTypeOptions(inputs).map(r =>
            <MenuItem
              value={r.name}
              id={"option-floorType-" + r.shortName}
              key={r.shortName}
            >{r.name}</MenuItem>
          )}
        </MySelect>

        <MySelect
          label="Day Count"
          className="mediumInput"
          id="dayCountSelect"
          value={inputs.dayCount}
          onChange={(dc) => dispatchInputs({ type: "dayCount", value: dc })}
        >
          {dayCountValues.map(r =>
            <MenuItem
              value={r.v1}
              id={"option-dayCount-" + r.v1}
              key={r.v1}
            >{r.name}</MenuItem>
          )}
        </MySelect>

        <MySelect
          label="Payment Holidays"
          className="largeInput"
          id="calendarSelect"
          multiple
          value={inputs.selectedCountryForHolidays}
          onChange={(h) => dispatchInputs({ type: "selectedCountryForHolidays", value: h })}
        >
          {calendars.map(c =>
            <MenuItem
              value={c}
              id={"option-calendar-" + c.replace(/\s/g, "")}
              key={c}
              disabled={c == rfr.defaultCalendar}
            >{c}</MenuItem>
          )}
        </MySelect>

        <MySelect
          label="RFR"
          className="mediumInput"
          id="rfrSelect"
          value={inputs.RFR}
          onChange={
            (r) => {
              dispatchInputs({ type: "RFR", value: r });
            }
          }
        >
          {RFRvalues.map(r =>
            <MenuItem
              value={r.name}
              id={"option-rfr-" + r.name}
              key={r.name}
            >{r.name}</MenuItem>
          )}
        </MySelect>

        <MySelect
          label="Interest Method"
          className="largestInput"
          id="interestMethodSelect"
          value={inputs.interestMethod}
          onChange={(im) => dispatchInputs({ type: "interestMethod", value: im })}
        >
          {allInterestMethodValues.map(im =>
            <MenuItem
              value={im.value}
              id={"option-im-" + im.value}
              key={im.value}
            >{im.name}</MenuItem>
          )}
        </MySelect>

        {
          isRoundingEnabled(inputs) ?
            <MySelect
              label="Rounding"
              className="mediumInput"
              id="roundingSelect"
              menuClassName="numberSelect"
              value={inputs.rateRounding}
              onChange={(r) => dispatchInputs({ type: "rateRounding", value: r })}
            >
              {roundingValues.map(r =>
                <MenuItem
                  className="numberItem"
                  value={r}
                  id={"option-rounding-" + r}
                  key={r}
                >{r}</MenuItem>
              )}
            </MySelect>
            : null
        }

        {
          isTermRateEnabled(inputs) ?
            <NumberInput
              label="Term Rate %"
              className="mediumInput"
              id="termRateInput"
              step={0.1}
              value={inputs.termRate}
              onInput={(v) => dispatchInputs({ type: "termRate", value: v ?? 0 })}
            />
            : null
        }

        <MyButton
          id="calculaterates"
          isSecondary={true}
          disabled={validationResults.invalidInput}
          onClick={onCalculate}
        >CALCULATE RATES</MyButton>

      </div>

      <Divider />

    </div>
  );
}

export default Inputs;
