import { FloorType, InputModel } from "../models/InputModel";
import { CommitmentChange } from "../models/InputModel";
import { InterestMethodValue } from "../models/UIRequestModel";
import { allFloorTypeValues } from "./AllInputOptions";

export function isBalanceTransferHidden(r: CommitmentChange, inputs: InputModel) {
  return r.change == 0 || inputs.interestMethod !== 'CompoundInArrears';
}

export function isInterestReceivedHidden(r: CommitmentChange) {
  return r.change >= 0;
}

export function isTermRateEnabled(inputs: InputModel) {
  return inputs.interestMethod === "Term";
}

export function isTenorEnabled(inputs: InputModel) {
  return inputs.interestMethod === "TermCME";
}

export function isRoundingEnabled(inputs: InputModel) {
  return !isTenorEnabled(inputs) && !isTermRateEnabled(inputs);
}

export function isNoSpread(inputs: InputModel) {
  return inputs.commitment.every(c => c.spread == null || c.spread == 0);
}

export function isFloorTypeDisabled(inputs: InputModel) {
  return (inputs.floor == null) || (isNoSpread(inputs) && (inputs.adjustment == null || inputs.adjustment == 0));
}

export function isLookbackDisabledRaw(interestMethod: InterestMethodValue) {
  return interestMethod === "Term";
}

export function isLookbackDisabled(inputs: InputModel) {
  return isLookbackDisabledRaw(inputs.interestMethod);
}

export function isLockoutDisabledRaw(interestMethod: InterestMethodValue) {
  return interestMethod === "Term" || interestMethod === "TermCME";
}

export function isLockoutDisabled(inputs: InputModel) {
  return isLockoutDisabledRaw(inputs.interestMethod);
}

export function isObservationShiftDisabled(inputs: InputModel) {
  return inputs.lookBack == 0
    || inputs.interestMethod === 'SimpleInArrears'
    || inputs.interestMethod === 'Term'
    || inputs.interestMethod === 'TermCME';
}

export function getFloorTypeOptions(inputs: InputModel): FloorType[] {
  if (inputs.floor == null) {
    return [];
  }
  const hasAllInRate = !isNoSpread(inputs);
  const hasInclAdj = inputs.adjustment != null && inputs.adjustment != 0;
  return allFloorTypeValues.filter(ft => (hasAllInRate || !ft.allInRate) && (hasInclAdj || !ft.inclAdj));
}
